import React from 'react'
import './HeaderFooter.css'

const Footer = () => {
  return (
    <div className='footer-container'>
        © 2022 BUG FIX SQUAD - RMIT University
    </div>
  )
}

export default Footer